@import './styles/reset.scss';

@font-face {
  font-family: myFont;
  src: url(../assets/fonts/CarnivaleeFreakshow-DLrm.ttf);
}

@font-face {
  font-family: myFont2;
  src: url(../assets/fonts/LostInSmileDemo-eZD0m.ttf);
}

@font-face {
  font-family: myFont3;
  src: url(../assets/fonts/MouldyCheeseRegular-WyMWG.ttf);
}

body {
  background: url("../assets/blueStripe.png");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}
#gameContainer {
  width: 1500px;
  height: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

#gameScreen {
  background-color: rgb(0, 170, 255);
  border: 5px solid rgb(242, 18, 18);
  border-style: outset;
  border-radius: 10px;
  border-width: 10px;
  margin: auto;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../assets/sky.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

#titleFont {
  text-align: center;
  font-family: myFont;
  color: red;
  border-radius: 10px;
  font-size: 80px;
  bottom: 100%;
  -webkit-text-stroke-width: .9px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

#titleContainer {
  justify-content: center;
}

#scoreboardContainer{
  border: 5px solid rgb(242, 18, 18);
  border-radius: 10px;
  background-color: rgb(16, 0, 240);
  color: white;
  min-width: 165px;
  height: 50px;
  padding: 5px;
  position: fixed;
}

#instructionModal {
  font-size: 20px;
  width: 600px;
  height: 300px;
  border-radius: 10px;
  border-width: 5px;
  border-color: #f70000;
  background-color: rgb(105, 190, 255);
  position: absolute;
  // top: 50%;
  // left: 50%;
  top: 300px;
  left: 600px;
  transform: translate(-50%, -50%);
}

#instructionModal a {
  text-decoration: none;
}

#instructionModal::backdrop {
  background-color: rgba(134, 134, 134, 0.5);
}

#instructionHeader {
  font-family: myfont3;
  text-decoration: underline;
  font-size: 50px;
  font-weight: 1px;
  color: rgba(0, 8, 255, 0.868);
}

#instructionModal > p {
  font-family: myfont3;
  color: rgba(0, 8, 255, 0.868);
}

#openModal {
  height: 50px;
  width: 100px;

  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  padding: 13px 23px;
  border: 1px solid #ffffff;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  background: #e63030;
  color: #ffffff;
}

#openModal:hover {
  border-color: #ff0000;
  background: #ffffff;
  color: #e63030;
}

#closeModal {
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  padding: 13px 23px;
  border: 1px solid #ffffff;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  background: #e63030;
  color: #ffffff;
}

#closeModal:hover {
  border-color: #ff0000;
  background: #ffffff;
  color: #e63030;
}

#closeModal::backdrop{
  background-color: rgba(134, 134, 134, 0.5);
}

#restart {
  display: inline-block;
  outline: none;
  height: 50px;
  width: 100px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  padding: 13px 23px;
  border: 1px solid #ffffff;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  background: #e63030;
  color: #ffffff;
}

#restart:hover {
  border-color: #ff0000;
  background: #ffffff;
  color: #e63030;
}

#endStats {
  border-radius: 10px;
  border-width: 5px;
  border-color: #f70000;
  background-color: rgb(105, 190, 255);
  color: rgba(0, 8, 255, 0.868);
  position: absolute;
}

#endStats > h2 {
  text-decoration: underline;
  // text-decoration-color: white;
  font-family: myfont3;
  font-size: 30px;
}

#endStats > p {
  flex: display;
  justify-content: space-evenly;
  font-size: 20px;
  font-family: myfont3;
}

#newGame {
  display: inline-block;
  outline: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  padding: 13px 23px;
  border: 1px solid #ffffff;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  background: #e63030;
  color: #ffffff;

}

#scoreboardContainer {
  font-family: myfont3;
  position: absolute;
}

#scoreboardWrapper {
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 400px;
}

#instructionHeader {
  text-align: justify;
}
#instructionBar > img {
  vertical-align: middle;
  display: inline-block;
}




